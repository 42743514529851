<template>
  <nav>
    <div class="nav_logo">
      <img src="./assets/logo.png">
    </div>
    <div class="nav_link">
      <router-link to="/">{{ $t('home.name') }}</router-link>
      <!-- <router-link to="/about">{{$t('buy_down.name')}}</router-link> -->
      <router-link to="/about">{{$t('about.name')}}</router-link>
    </div>
  </nav>
  <router-view/>
</template>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1C1C1C;
  width: window-width;
  min-width: 1200px;
}

nav {
  height: 80px;
  display: flex;
  position: fixed;
  z-index: 1000;
  width: 100%;
  font-size: 18px;
  align-items: center;
  background: #FEFEFE;
  justify-content: space-between;
  .nav_logo{
    padding-left: 100px;
    img{
      width: 100px;
      height: 20px;
    }
  }
  .nav_link{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  a {
    color: #1C1C1C;
    text-decoration: none;
    margin-left: 80px;
    &.router-link-exact-active {
      color: #42b983;
    }
    &:last-child{
      padding-right: 100px;
    }
  }
}
</style>
