export default {
    home: {
      name: 'home',
    },
    tabs: {
      work: 'Work',
      private: 'Private',
      collect: 'Collect',
      like: 'Like',
    },
  }
  