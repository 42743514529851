<template>
  <div class="home">
    <!-- 首页大图 -->
      <div class="home_img">
        <img class="img_big" src="../assets/home.png" alt="">
        <img class="img_small" src="../assets/home_logo.png" alt="">
      </div>
      <!-- 首页内容 -->
      <div class="home_content">
        <div class="title">
          <span>{{$t('home.title_list')}} <span style="color:#EA5514">{{$t('home.title_list_Highlight')}}</span> </span>
        </div>
        <div class="tips">
          {{ $t('home.Intelligent_list') }}
        </div>
        <div class="detail">
          {{ $t('home.Intelligent_electrocardiograph') }} <br>
          {{ $t('home.Intelligent_electrocardiographs') }}
        </div>
        <div class="about">
          <div class="about_detail">
            <img src="../assets/Group44.png" alt="">
            <div class="img_de">{{ $t('home.Intelligent.one') }}</div>
          </div>
          <div class="about_detail1">
            <img src="../assets/2.png" alt="">
            <div class="img_de">{{ $t('home.Intelligent.two') }}</div>
          </div>
          <div class="about_detail2">
            <img src="../assets/Maskgroup.png" alt="">
            <div class="img_de">{{ $t('home.Intelligent.three') }}</div>
          </div>
        </div>
        <div class="buy">
          <button class="buy_button">{{$t('home.buyButton') }} ></button>
          <div class="starting">
            {{$t('home.price')}}{{$t('home.StartingForm')}}
          </div>
        </div>
        <div class="good">
          <div class="good_title">
            <span>{{$t('home.title_list')}} <span style="color:#EA5514">{{$t('home.goodTitle')}}</span> </span>
          </div>
          <div class="good_det">
            <div>
              {{ $t('home.good_det') }}
            </div>
            <div>
              {{ $t('home.good_det2') }}
            </div>
          </div>
          <div class="good_list">
            <div class="good_list1">
              {{ $t('home.good_list.one') }}
            </div>
            <div class="good_list1">
              {{ $t('home.good_list.two') }}
            </div>
            <div class="good_list1">
              {{ $t('home.good_list.three') }}
            </div>
            <div class="good_list1">
              {{ $t('home.good_list.four') }}
            </div>
          </div>
        </div>
        <div class="scene">
          <div class="scene_title">
            {{ $t('home.scene_title') }}
          </div>
          <div class="scene_daily">
            {{ $t('home.scene_daily') }}
          </div>
          <div class="scene_daily_detail">
            {{ $t('home.scene_daily_detail.one') }}
          </div>
          <div class="scene_daily_detail">
            {{ $t('home.scene_daily_detail.two') }}
          </div>
          <div class="scene_sky">
            <el-carousel :interval="4000" :autoplay="false" type="card" height="650px" indicator-position="none">
              <el-carousel-item v-for="item in 3" :key="item">
                <h3 class="medium">{{ item }}</h3>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="scene_two">
          <div class="scene_title">
            {{ $t('home.scene_title') }}
          </div>
          <div class="scene_daily_detail">
            {{ $t('home.scene_daily_detail.one') }}
          </div>
          <div class="scene_daily_detail">
            {{ $t('home.scene_daily_detail.two') }}
          </div>
          <div class="scene_two_detail">
            <div class="scene_two_div">
              <img src="../assets/image2.png" alt="">
              <div class="scene_two_divs">
                <div class="scene_two_divs_de">
                  <div class="scene_two_divs_de_title">
                    {{$t('home.daily_test.one.title') }}
                  </div>
                  <div class="scene_two_divs_de_detal">
                    {{$t('home.daily_test.one.des1') }}
                  </div>
                  <div>
                    {{$t('home.daily_test.one.des2') }}
                  </div>
                </div>
                <div class="scene_two_divs_de">

                </div>
                <div class="scene_two_divs_de">

                </div>
                <div class="scene_two_divs_de">

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hardware">
          <div class="hardware_title">
            {{ $t('home.hardware_title') }}
          </div>
          <div class="hardware_title_li">
            {{ $t('home.hardware_title_li') }}
          </div>
          <div class="hardware_img">
            <div class="hardware_img_text">
              <div class="hardware_img_texts">
                {{ $t('home.hardware_img_text.one') }}
              </div>
              <div class="hardware_img_texts">
                {{ $t('home.hardware_img_text.two') }}
              </div>
            </div>
            <div class="hardware_img_right">
              <p>{{ $t('home.hardware_img_right.one') }}</p>
              <p>{{ $t('home.hardware_img_right.two') }}</p>
              <p>{{ $t('home.hardware_img_right.three') }}</p>
            </div>
          </div>
          <div class="hardware_img_detail">
            <div class="hardware_img_detail_top">
              {{ $t('home.hardware_img_detail_top') }}
            </div>
            <div class="hardware_img_detail_two"> 
              <div class="hardware_img_detail1">
                {{ $t('home.hardware_img_detail.one') }}
              </div>
              <div class="hardware_img_detail2">
                <p style="margin:0px">{{ $t('home.hardware_img_detail.two.one') }}</p>
                <p>{{ $t('home.hardware_img_detail.two.two') }}</p>
                <p style="margin:0px">{{ $t('home.hardware_img_detail.two.three') }}</p>
              </div>
              <div>
                {{ $t('home.hardware_img_detail.three') }}
              </div>
            </div>
          </div>
          <div class="hardware_lists">
            <div class="hardware_list one two">
              <div class="hardware_list_title">
                {{ $t('home.hardware_lists.one.title') }}
              </div>
              <div class="hardware_list_des">
                {{ $t('home.hardware_lists.one.des') }}
              </div>
              <div class="hardware_list_img">
                <div v-for="item in 4" :key="item" class="hardware_list_img_div">

                </div>
              </div>
            </div>
            <div class="hardware_list two">
              <div class="hardware_list_title">
                {{ $t('home.hardware_lists.two.title') }}
              </div>
              <div class="hardware_list_des">
                {{ $t('home.hardware_lists.two.des') }}
              </div>
              <div class="hardware_list_img">
                <div v-for="item in 4" :key="item" class="hardware_list_img_div">

                </div>
              </div>
            </div>
            <div class="hardware_list one">
              <div class="hardware_list_title ">
                {{ $t('home.hardware_lists.three.title') }}
              </div>
              <div class="hardware_list_des">
                {{ $t('home.hardware_lists.three.des') }}
              </div>
              <div class="hardware_list_img">
                <div v-for="item in 4" :key="item" class="hardware_list_img_div">

                </div>
              </div>
            </div>
            <div class="hardware_list">
              <div class="hardware_list_title">
                {{ $t('home.hardware_lists.four.title') }}
              </div>
              <div class="hardware_list_des">
                {{ $t('home.hardware_lists.four.des') }}
              </div>
              <div class="hardware_list_img">
                <div v-for="item in 4" :key="item" class="hardware_list_img_div">

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chara">
          <div class="chara_title">
            <span>{{$t('home.title_list')}} 
              <span style="color:#EA5514">{{$t('home.chara')}}</span> 
            </span>
          </div>
          <div class="chara_des">
            <div class="chara_des1">
              {{ $t('home.chara_des.one') }}
            </div>
            <div class="chara_des2">
              {{ $t('home.chara_des.two') }}
            </div>
          </div>
          <div class="chara_lists">
            <div class="chara_list" 
              v-for="item in ['one','two','three','four']" 
              :key="item">
              <div class="chara_list_img">
                
              </div>
              <div class="chara_list_title">
                {{ $t(`home.chara_lists.${item}.title`) }}
              </div>
              <div class="chara_list_des">
                {{ $t(`home.chara_lists.${item}.des`) }}
              </div>
            </div>
          </div>
        </div>
        <div class="more">
          <div class="more_title">
            {{ $t('home.more_title') }}
          </div>
          <div class="more_des">
            {{ $t('home.more_des') }}
          </div>
          <div class="more_des_lists" 
            >
            <div class="more_des_list" 
            v-for="item in ['left', 'right']" 
            :key="item">
              <div class="more_des_item"
              v-for="ot in ['one','two','three','four','five']"
              :key="ot">
                <div class="more_des_item_title">
                  <span v-if="$t(`home.more_des_lists.${item}.${ot}.title`) == '导联'"
                  style="color: #EA5514;">
                    4
                  </span>
                  {{ $t(`home.more_des_lists.${item}.${ot}.title`) }}
                </div>
                <div class="more_des_item_des">
                  {{ $t(`home.more_des_lists.${item}.${ot}.des`) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mechan">
          <div class=mechan_des>
            <div class="mechan_des_left">
              <span class="mechan_des_left_title1">
                {{ $t('home.mechan_des_left_title.one') }}
              </span>
              <span  class="mechan_des_left_title2">
                {{ $t('home.mechan_des_left_title.two') }}
              </span>
              <span class="mechan_des_left_title3">
                {{ $t('home.mechan_des_left_title.three') }}
              </span>
              <span class="mechan_des_left_title4">
                {{ $t('home.mechan_des_left_title.four') }}
              </span>
              <span class="mechan_des_left_title5">
                {{ $t('home.mechan_des_left_title.five') }}
              </span>
              <span class="mechan_des_left_title5">
                {{ $t('home.mechan_des_left_title.six') }}
              </span>
            </div>
            <div class="mechan_des_right">
              <div class="mechan_des_right_li"
                v-for="item in 3" :key="item">
                <div class="mechan_des_right_img">

                </div>
                <div class="mechan_des_right_text">
                  {{ $t('home.mechan_des_right_text') }}
                </div>
              </div>
            </div>
          </div>
          <div class="mechan_img">
            <div class="mechan_img_div"
            v-for="item in 6" :key="item">

            </div>
          </div>
        </div>
        <div class="require">
          <div class="require_title">
            {{ $t('home.require_title.one') }}<span style="color:#EA5514">{{ $t('home.require_title.two') }}</span>
          </div>
          <div class="require_des">
            <div>
              {{ $t('home.require_des.one') }}
            </div>
            <div>
              {{ $t('home.require_des.two') }}
            </div>
          </div>
          <div class="require_img">
            <div class="require_img_left">
              <img src="../assets/group1.png" alt="">
            </div>
            <div class="require_img_right">
              <img src="../assets/home_logo.png" alt="">
              <div class="require_img_right_text">
                <div class="require_img_right_texts" v-for="(item,index) in ['one','two','three']" :key="index">
                  <div class="text_num">
                    {{ index }}
                  </div>
                  <div class="text_list">
                    <div class="text_des_title">
                      {{ $t(`home.require_img_text.${item}.title`) }}
                    </div>
                    <div class="text_des_title2">
                      {{ $t(`home.require_img_text.${item}.des`) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="product">
          <div class="product_title">
            {{ $t('home.product_title') }}
          </div>
          <div class="product_des">
            <div class="product_des_buy">
              <div class="product_des_buy_title">
                {{ $t('home.product_des_buy.title') }}
              </div>
              <div class="product_des_buy_des">
                {{ $t('home.product_des_buy.des') }}
              </div>
              <div class="product_des_buy_but">
                {{ $t('home.price') }}
                {{ $t('home.buyButton') }} >
              </div>
            </div>
            <div class="product_des_down">
              <img src="../assets/Group.png" alt="">
              <div class="product_des_downlist">
                <div class="product_des_downlist_title">
                  {{ $t('home.product_des_down.title') }}
                </div>
                <div class="product_des_downlist_des">
                  {{ $t('home.product_des_down.des') }}
                </div>
                <div class="product_des_downlist_channel">
                  <div class="product_des_downlist_channel_ios">
                    {{ $t('home.down_channel.ios') }} >
                  </div>
                  <div>
                    {{ $t('home.down_channel.Android') }} >
                  </div>
                </div>
              </div>
              <div class="scan_the_code">
                <div class="scan_the_qrcode">

                </div>
                <div class="scan_the_code_text">
                  {{ $t('home.scan_the_code') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="official">
          <div class="address">
            <div class="address_logo">
              <img src="../assets/logo.png" alt="">
            </div>
            <div class="address_title">
              {{ $t('home.address_title') }}
            </div>
            <div class="address_des">
              {{ $t('home.address_des') }}
            </div>
            <div class="company">
              {{ $t('home.company') }}
            </div>
          </div>
          <div class="official_des">
            <div class="official_des_code">

            </div>
            <div class="official_des_text">
              {{ $t('home.official_code') }}
            </div>
          </div>
        </div>
        <div class="footer">
          {{ $t('home.footer') }}
        </div>
      </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'HomeView',
  components: {
    HelloWorld,
  },
});
</script>
<style lang="less" scoped>
@import url(../styles/homeview.less);

</style>
