export default {
    home: {
      name: '首页',
      title_list:'生理监护智能衣',
      title_list_Highlight:'包含清单',
      chara:'特性',
      Intelligent_list:'*包装内含 智能心电仪 + 高科技超弹衣 + 使用说明 + 充电线*',
      Intelligent_electrocardiograph:'智能心电仪心电信号媲美专业级，提供实时远程监护；高科技超弹衣符合人体工程学设计的穿戴设备，选用高级面料穿戴无负担；',
      Intelligent_electrocardiographs:'心电仪App通过蓝牙控制智能心电仪和高科技超弹衣，便捷开始心电检测、查看检测报告、了解家庭成员健康状况',
      Intelligent:{
        one:'智能心电仪',
        two:'高科技超弹衣',
        three:'心电衣app'
      },
      buyButton:'购买',
      price:'￥1899',
      StartingForm:'起',
      goodTitle:'优势',
      good_det:'符合人体工程学设计的穿戴设备，选用高级面料穿戴无负担；心电信号媲美专业级，提供实时远程监护；',
      good_det2:'增加AI人工智能技术，风险自动预警；穿戴使用简单、便于操作，普适性高；同时具有高扩展性，未来提供更多升级服务',
      good_list:{
        one:'精确度高',
        two:'便捷性佳',
        three:'功能性好',
        four:'扩展性好'
      },
      scene_title:'应用场景',
      scene_daily:'日常检测及监测',
      scene_daily_detail:{
        one:'用于日常生活、学习、工作和运动中的心脏健康监护，可以随时启动检测，也可以按照系统默认每半小时检测一次。',
        two:'对于其他穿戴者，可以随时进行远程检测和监测。'
      },
      daily_test:{
        one:{
            title:'日常检测及监测',
            des1:'用于日常生活、学习、工作和运动中的心脏健康监护。',
            des2:'可以对家人、朋友进行远程检测和预警。'
        }
      },
      hardware_title:'强悍的硬件，还有首屈一指软件App',
      hardware_title_li:'轻触一下，开始检测',
      hardware_img_text:{
        one:'为了让你拥有更加灵敏、流畅的用机体验，我们引入了虚拟显卡，让你在打开和退出应用时，系统响应几乎零延迟。 ',
        two:'同时，在虚拟显卡框架下，我们对灵敏度和帧同步进行了调试，让你的触控响应会更灵敏，每一帧的画面同步更及时。'
      },
      hardware_img_right:{
        one:'1、组员列表',
        two:'2、围绕环形头像',
        three:'远程检测'
      },
      hardware_img_detail_top:'手持启动页面 / AI支持 / 检测按钮 / 蓝牙连接 / 检测报告 / 家庭组 / 扫码 / 媲美12导联',
      hardware_img_detail:{
        one:'2.5D',
        two:{
            one:'检测正常/异常',
            two:'心电波形',
            three:'支持病种检测'
        },
        three:'简单的扫码示意'
      },
      hardware_lists:{
        one:{
            title:'个人信息',
            des:'心电信号媲美专业级心电信号，提供实时心电监护功能，提供远程关爱监护功能'
        },
        two:{
            title:'家庭组',
            des:'心电信号媲美专业级心电信号，提供实时心电监护功能，提供远程关爱监护功能'
        },
        three:{
            title:'扫码',
            des:'心电信号媲美专业级心电信号，提供实时心电监护功能，提供远程关爱监护功能'
        },
        four:{
            title:'蓝牙连接',
            des:'心电信号媲美专业级心电信号，提供实时心电监护功能，提供远程关爱监护功能'
        }
      },
      chara_des:{
        one:'符合人体工程学设计的穿戴设备，心电信号媲美专业级，提供实时远程监护；增加AI人工智能技术，风险自动预警；',
        two:'穿戴使用简单、便于操作，普适性高；同时具有高扩展性，未来提供更多升级服务'
      },
      chara_lists:{
        one:{
            title:'易用性',
            des:'智能衣穿戴、电极贴片的固定、以及传感器的佩戴都十分简单，便于操作，普适性高',
        },
        two:{
            title:'科技性',
            des:'使用AI人工智能技术，提供实时心电信号分析报告，对于风险问题进行预警'
        },
        three:{
            title:'实用性',
            des:'心电信号媲美专业级心电信号，提供实时心电监护功能，提供远程关爱监护功能'
        },
        four:{
            title:'轻便性',
            des:'轻便性：智能衣材料高级，重量很轻，穿戴无负担感'
        }
      },
      more_title:'往下看，更多精彩',
      more_des:'我们的产品相比市场上已有的相似产品，更有竞争优势，同时在检测准确度、使用便捷方面更是无可比拟',
      more_des_lists:{
        left:{
            one:{
                title:'多种穿戴方式',
                des:'多种服装样式可供穿戴'
            },
            two:{
                title:'及时的检测报告',
                des:'多种服装样式可供穿戴'
            },
            three:{
                title:'实时预警',
                des:'多种服装样式可供穿戴'
            },
            four:{
                title:'远程检测',
                des:'多种服装样式可供穿戴'
            },
            five:{
                title:'记录时长',
                des:'60s/30min'
            },
        },
        right:{
            one:{
                title:'导联',
                des:'多种服装样式可供穿戴'
            },
            two:{
                title:'AI智能分析',
                des:'多种服装样式可供穿戴'
            },
            three:{
                title:'远程监护与提醒',
                des:'多种服装样式可供穿戴'
            },
            four:{
                title:'扩展性',
                des:'多种服装样式可供穿戴'
            },
            five:{
                title:'合理的售价',
                des:'￥1899，方便更多人群购买检测，为疾病预防提供力所能及的支持'
            },
        }
      },
      mechan_des_left_title:{
        one:'权威机构认证',
        two:'国际领先水平',
        three:'「项目总体技术达到国际先进水平，其中超长流长「结构-材料-工艺性能」研究方法和材料性能预测模型达到国际领先水平」',
        four:'「科技成果与专利证书」',
        five:'我们通过了多个',
        six:'权威安全机构认证和测评'
      },
      mechan_des_right_text:'专利证书',
      footer:'Copyright © 杭州护脉科技有限公司. All Rights Reserved.',
      require_title:{
        one:'产品',
        two:'技术说明'
      },
      require_des:{
        one:'符合人体工程学设计的穿戴设备，心电信号媲美专业级，提供实时远程监护；增加AI人工智能技术，风险自动预警；',
        two:'穿戴使用简单、便于操作，普适性高；同时具有高扩展性，未来提供更多升级服务'
      },
      require_img_text:{
        one:{
            title:'高科技超弹衣',
            des:'High-Tech UItra-Elastic Fabric'
        },
        two:{
            title:'AI神经网络处理器',
            des:'Al Neural Network Processing Unit'
        },
        three:{
            title:'智能信号采集点',
            des:'Intelligent Signal Acquisition Nodes'
        },
      },
      product_title:'优质产品， 一同体验',
      product_des_buy:{
        title:'购买我们的产品',
        des:'我们致力提供极致的产品体验，如需购买请点击前往',
      },
      product_des_down:{
        title:'搭配App同步使用',
        des:'在手机上开始检测和查看检测报告和个人信息'
      },
      scan_the_code:'扫码直接下载',
      down_channel:{
        ios:'App Store',
        Android:'安卓App'
      },
      address_title:'让大众拥有健康医疗',
      address_des:'公司地址：杭州市余杭区中泰街道仙桥路16号丝腾中泰科技园1幢2楼269室',
      company:'Copyright © 2024 杭州护脉科技有限公司 Inc. 保留所有权利。',
      official_code:'微信公众号'
    },
    buy_down:{
      name:'购买与下载'
    },
    about:{
      name:'关于护脉'
    },
    tabs: {
      work: '作品',
      private: '私密',
      collect: '收藏',
      like: '喜欢',
    },
  }
  